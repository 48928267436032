import React, { useEffect, useState } from 'react';
import { db } from '../Firebase';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { useNavigate } from "react-router-dom";

const AddCandidate = () => {
  const [formData, setFormData] = useState({
    date: '',
    candidateName: '',
    mobileNumber: '',
    city: '',
    degree: '',
    category: '',
    type: '',
    comments: '',
    followupDate: '',
    finalStatus: '',
    referredBy: '',
    customReferrer: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [admins, setAdmins] = useState([]); // State to store the list of admins
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const adminsCollection = collection(db, 'admin');
        const adminsSnapshot = await getDocs(adminsCollection);
        const adminsData = adminsSnapshot.docs.map(doc => doc.data().name);
        setAdmins(adminsData);
      } catch (error) {
        console.error('Error fetching admins:', error);
      }
    };

    fetchAdmins();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Check if the mobile number already exists
    const leadsRef = collection(db, 'leads');
    const querySnapshot = await getDocs(query(leadsRef, where('mobileNumber', '==', formData.mobileNumber)));

    if (!querySnapshot.empty) {
      // Phone number already exists, display error message
      setErrors(prevErrors => ({
        ...prevErrors,
        mobileNumber: 'Mobile number already exists'
      }));
      setIsLoading(false);

      // Scroll to the input field with the error
      const errorInput = document.getElementById('mobileNumber');
      if (errorInput) {
        errorInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    // Create a new comment object with text and date
    const newComment = {
      comment: formData.comments,
      dateTime: new Date().toISOString()
    };

    // Continue with form submission
    const finalReferredBy = (formData.referredBy === 'Others' || formData.referredBy === 'Oranium Students') && formData.customReferrer.trim() !== '' ? formData.customReferrer.trim() : formData.referredBy;
    let formDataToSubmit = { ...formData };

    // Remove customReferrer from formDataToSubmit if referredBy is not 'Others' or 'Oranium Students' or customReferrer is empty
    if ((formDataToSubmit.referredBy !== 'Others' && formDataToSubmit.referredBy !== 'Oranium Students') || formDataToSubmit.customReferrer.trim() === '') {
      delete formDataToSubmit.customReferrer;
    }

    // Check for empty fields and set errors
    const newErrors = {};
    Object.keys(formDataToSubmit).forEach((key) => {
      if (formDataToSubmit[key] === '' && key !== 'customReferrer') { // Exclude customReferrer
        newErrors[key] = 'This field is required';
        setIsLoading(false);
      }
    });
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      // Scroll to the input field with the first error
      const errorKeys = Object.keys(newErrors);
      const firstErrorInput = document.getElementById(errorKeys[0]);
      if (firstErrorInput) {
        firstErrorInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    try {
      const docRef = await addDoc(collection(db, 'leads'), {
        ...formDataToSubmit,
        // Set comments to an array containing only the new comment object
        comments: [newComment],
        referredBy: finalReferredBy,
      });

      setIsLoading(false);
      navigate('/');
    } catch (error) {
      console.error('Error adding document:', error);
      setIsLoading(false);
    }
  };

  const categoryToTypes = {
    Testing: ['Java Selenium', 'Python Selenium'],
    Cloud: ['AWS Devops', 'Azure Devops'],
    Development: ['Java Fullstack', 'Python Fullstack', 'Dot Net Fullstack', 'Fullstack', 'Front-End', 'Back-End', 'JS Family', 'MERN Stack'],
    'Oracle SQL': ['PLSQL Development', 'Production Support'],
    'UIUX Design': ['UIUX only', 'UIUX+Development'],
    'Data Analytics/Data Science': ['DA Stack', 'DataScience', 'PowerBI', 'Tableau', 'Python with ML', 'AI'],
    'Fresher Placement': ['Mention Technology name'],
    'Experienced Placement': ['Mention Technology name'],
    'Documents/Test only': ['Work Experience Document', 'Certification test', 'Client Round', 'Online Test', 'Single Interview'],
    Others: [''] // Editable box (Left in blank)
  };

  const NavigateBack = () => {
    navigate('/');
  }

  return (
    <div className="flex justify-center bg-slate-50">
      <div className="w-full max-w-md mt-10">
        <div className='flex justify-between items-center'>
          <h2 className="text-2xl font-bold mb-4">Add Candidate</h2>
          <button
            onClick={NavigateBack}
            className="bg-slate-900 text-white px-4 py-1 rounded-md hover:bg-slate-600 focus:outline-none focus:ring focus:ring-slate-200"
          >
            Back
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Date Input */}
          <div className="mb-4">
            <label htmlFor="date" className="block text-sm font-semibold mb-2">
              Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              className={`w-full p-2 border border-gray-300 rounded ${errors.date && 'border-red-500'}`}
            />
            {errors.date && <p className="text-red-500 text-sm mt-1">{errors.date}</p>}
          </div>

          {/* Candidate Name Input */}
          <div className="mb-4">
            <label htmlFor="candidateName" className="block text-sm font-semibold mb-2">
              Candidate Name
            </label>
            <input
              type="text"
              id="candidateName"
              name="candidateName"
              value={formData.candidateName}
              onChange={handleChange}
              className={`w-full p-2 border border-gray-300 rounded ${errors.candidateName && 'border-red-500'}`}
            />
            {errors.candidateName && <p className="text-red-500 text-sm mt-1">{errors.candidateName}</p>}
          </div>
          {/* Mobile Number Input */}
          <div className="mb-4">
            <label htmlFor="mobileNumber" className="block text-sm font-semibold mb-2">
              Mobile Number
            </label>
            <input
              type="text"
              id="mobileNumber"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              className={`w-full p-2 border border-gray-300 rounded ${errors.mobileNumber && 'border-red-500'}`}
            />
            {errors.mobileNumber && <p className="text-red-500 text-sm mt-1">{errors.mobileNumber}</p>}
          </div>
          {/* City Input */}
          <div className="mb-4">
            <label htmlFor="city" className="block text-sm font-semibold mb-2">
              City
            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className={`w-full p-2 border border-gray-300 rounded ${errors.city && 'border-red-500'}`}
            />
            {errors.city && <p className="text-red-500 text-sm mt-1">{errors.city}</p>}
          </div>
          {/* Degree Input */}
          <div className="mb-4">
            <label htmlFor="degree" className="block text-sm font-semibold mb-2">
              Degree
            </label>
            <input
              type="text"
              id="degree"
              name="degree"
              value={formData.degree}
              onChange={handleChange}
              className={`w-full p-2 border border-gray-300 rounded ${errors.degree && 'border-red-500'}`}
            />
            {errors.degree && <p className="text-red-500 text-sm mt-1">{errors.degree}</p>}
          </div>
          {/* Category Dropdown */}
          <div className="mb-4">
            <label htmlFor="category" className="block text-sm font-semibold mb-2">
              Category
            </label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className={`w-full p-2 border border-gray-300 rounded ${errors.category && 'border-red-500'}`}
            >
              <option value="">Select Category</option>
              <option value="Testing">Testing</option>
              <option value="Cloud">Cloud</option>
              <option value="Development">Development</option>
              <option value="Oracle SQL">Oracle SQL</option>
              <option value="UIUX Design">UIUX Design</option>
              <option value="Data Analytics/Data Science">Data Analytics/Data Science</option>
              <option value="Fresher Placement">Fresher Placement</option>
              <option value="Experienced Placement">Experienced Placement</option>
              <option value="Documents/Test only">Documents/Test only</option>
              <option value="Others">Others</option>
            </select>
            {errors.category && <p className="text-red-500 text-sm mt-1">{errors.category}</p>}
          </div>
          {/* Type Input */}
          <div className="mb-4">
            <label htmlFor="type" className="block text-sm font-semibold mb-2">
              Type
            </label>
            {formData.category === 'Others' ? (
              <input
                type="text"
                id="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
                placeholder="Enter custom type"
                className={`w-full p-2 border border-gray-300 rounded ${errors.type && 'border-red-500'}`}
              />
            ) : (
              <>
                {formData.category === 'Fresher Placement' || formData.category === 'Experienced Placement' ? (
                  <input
                    type="text"
                    id="type"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    placeholder="Mention Technology name"
                    className={`w-full p-2 border border-gray-300 rounded ${errors.type && 'border-red-500'}`}
                  />
                ) : (
                  <select
                    id="type"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    className={`w-full p-2 border border-gray-300 rounded ${errors.type && 'border-red-500'}`}
                  >
                    <option value="">Select Type</option>
                    {categoryToTypes[formData.category] && categoryToTypes[formData.category].map((type, index) => (
                      <option key={index} value={type}>{type}</option>
                    ))}
                  </select>
                )}
              </>
            )}
            {errors.type && <p className="text-red-500 text-sm mt-1">{errors.type}</p>}
          </div>

          {/* Comments Input */}
          <div className="mb-4">
            <label htmlFor="comments" className="block text-sm font-semibold mb-2">
              Comment
            </label>
            <textarea
              id="comments"
              name="comments"
              value={formData.comments}
              onChange={handleChange}
              className={`w-full p-2 border border-gray-300 rounded ${errors.comments && 'border-red-500'}`}
            />
            {errors.comments && <p className="text-red-500 text-sm mt-1">{errors.comments}</p>}
          </div>
          {/* Follow-up Date Input */}
          <div className="mb-4">
            <label htmlFor="followupDate" className="block text-sm font-semibold mb-2">
              Follow Up On
            </label>
            <input
              type="date"
              id="followupDate"
              name="followupDate"
              value={formData.followupDate}
              onChange={handleChange}
              className={`w-full p-2 border border-gray-300 rounded ${errors.followupDate && 'border-red-500'}`}
            />
            {errors.followupDate && <p className="text-red-500 text-sm mt-1">{errors.followupDate}</p>}
          </div>
          {/* Final Status Dropdown */}
          <div className="mb-4">
            <label htmlFor="finalStatus" className="block text-sm font-semibold mb-2">
              Status
            </label>
            <select
              id="finalStatus"
              name="finalStatus"
              value={formData.finalStatus}
              onChange={handleChange}
              className={`w-full p-2 border border-gray-300 rounded ${errors.finalStatus && 'border-red-500'}`}
            >
              <option value="">Select Final Status</option>
              <option value="Positive">Positive</option>
              <option value="Pending">Pending</option>
              <option value="Low">Low</option>
              <option value="Hold">Hold</option>
              <option value="Negative">Negative</option>
              <option value="Joined">Joined</option>
            </select>
            {errors.finalStatus && <p className="text-red-500 text-sm mt-1">{errors.finalStatus}</p>}
          </div>
          {/* Referred  */}
          <div className="mb-4">
            <label htmlFor="referredBy" className="block text-sm font-semibold mb-2">
              Referred By
            </label>
            <input
              type="text"
              id="referredBy"
              name="referredBy"
              value={formData.referredBy}
              onChange={handleChange}
              placeholder='Enter Name'
              className={`w-full p-2 border border-gray-300 rounded ${errors.referredBy && 'border-red-500'}`}
            />
            {errors.referredBy && <p className="text-red-500 text-sm mt-1">{errors.referredBy}</p>}
          </div>
          {/* Submit Button */}
          <button type="submit" className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded w-full mt-4">
            {isLoading ? 'Adding...' : 'Add Candidate'}
          </button>
        </form>
      </div>
    </div>
  )

}

export default AddCandidate