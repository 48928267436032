import { collection, doc, getDocs, deleteDoc, where, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../Firebase';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import logo from '../assets/logo.png';

const Home = () => {
  const [user, setUser] = useState('');
  const [originalTableData, setOriginalTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    date: [],
    category: [],
    type: [],
    followup: [],
    status: [],
    referredBy: [],
  });
  const [statusCounts, setStatusCounts] = useState({});

  const navigate = useNavigate();
  const handleDownloadExcel = () => {
    // Prepare data for Excel export
    const dataForExport = tableData.map(item => ({
      Date: item.date,
      Name: item.candidateName,
      Number: item.mobileNumber,
      City: item.city,
      Degree: item.degree,
      Category: item.category,
      Type: item.type,
      Comment: item.comments.length > 0 ? item.comments[item.comments.length - 1].comment : '',
      'Follow-up On': item.followupDate,
      Status: item.finalStatus,
      'Referred By': item.referredBy
    }));

    // Convert data to Excel workbook
    const worksheet = XLSX.utils.json_to_sheet(dataForExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Leads');

    // Save the Excel file
    XLSX.writeFile(workbook, 'leads.xlsx');
  };

  const handleRedirect = (docId) => {
    navigate(`/edit/${docId}`);
  };

  const [selectedFilters, setSelectedFilters] = useState(() => {
    const storedFilters = localStorage.getItem('filters');
    return storedFilters ? JSON.parse(storedFilters) : {
      date: [],
      category: [],
      type: [],
      followup: [],
      status: [],
      referredBy: [],
    };
  });

  // Function to apply filters to leads data
const applyFilters = (data, filters) => {
  return data.filter((item) => {
    // Date filter logic
    const isInDateRange =
      filters.date.length === 0 ||
      (filters.date.includes('last3days') && new Date(item.date) >= new Date(new Date() - 3 * 24 * 60 * 60 * 1000)) ||
      (filters.date.includes('last7days') && new Date(item.date) >= new Date(new Date() - 7 * 24 * 60 * 60 * 1000)) ||
      (filters.date.includes('thismonth') && new Date(item.date).getMonth() === new Date().getMonth()) ||
      (filters.date.includes('last2months') &&
        new Date(item.date) >= new Date(new Date() - 2 * 30 * 24 * 60 * 60 * 1000)) ||
      (filters.date.includes('last3months') &&
        new Date(item.date) >= new Date(new Date() - 3 * 30 * 24 * 60 * 60 * 1000));

    // Follow-up date filter logic
    const isInFollowupRange =
      filters.followup.length === 0 ||
      (filters.followup.includes('next7days') &&
        new Date(item.followupDate) <= new Date(new Date() + 7 * 24 * 60 * 60 * 1000)) ||
      (filters.followup.includes('last7days') &&
        new Date(item.followupDate) >= new Date(new Date() - 7 * 24 * 60 * 60 * 1000)) ||
      (filters.followup.includes('1month') && new Date(item.followupDate) >= new Date(new Date() - 30 * 24 * 60 * 60 * 1000)) ||
      (filters.followup.includes('2months') &&
        new Date(item.followupDate) >= new Date(new Date() - 2 * 30 * 24 * 60 * 60 * 1000));

    // Category filter logic
    const isCategoryMatch = filters.category.length === 0 || filters.category.includes(item.category);

    // Status filter logic
    const isStatusMatch = filters.status.length === 0 || filters.status.includes(item.finalStatus);

    // Return true if all filters pass, false otherwise
    return isInDateRange && isInFollowupRange &&  isCategoryMatch && isStatusMatch;
  });
};



  const fetchData = async () => {
    try {

      const querySnapshot = await getDocs(query(collection(db, "leads"), where('finalStatus', 'not-in', ['Joined', 'Negative'])));

      const leadsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setOriginalTableData(leadsData);

      // Apply filters to the leads data
      const filteredData = applyFilters(leadsData, selectedFilters);
      setTableData(filteredData);
      // Extract unique values for each filter option
      const uniqueDates = [...new Set(leadsData.map((item) => item.date))];
      const uniqueCategories = [...new Set(leadsData.map((item) => item.category))];
      const uniqueTypes = [...new Set(leadsData.map((item) => item.type))];
      const uniqueFollowups = [...new Set(leadsData.map((item) => item.followupDate))];
      const uniqueStatuses = [...new Set(leadsData.map((item) => item.finalStatus))];
      const uniqueReferredBys = [...new Set(leadsData.map((item) => item.referredBy))];

      setFilterOptions({
        date: uniqueDates,
        category: uniqueCategories,
        type: uniqueTypes,
        followup: uniqueFollowups,
        status: uniqueStatuses,
        referredBy: uniqueReferredBys,
      });

      // Calculate status counts
      const counts = {};
      leadsData.forEach((item) => {
        const status = item.finalStatus;
        counts[status] = (counts[status] || 0) + 1;
      });
      setStatusCounts(counts);
    } catch (error) {
      console.error('Error fetching leads data:', error);
    }
  };


  const handleApplyFilter = () => {
    const filteredData = originalTableData.filter((item) => {
      const isInDateRange =
        selectedFilters.date.length === 0 ||
        (selectedFilters.date.includes('last3days') &&
          new Date(item.date) >= new Date(new Date() - 3 * 24 * 60 * 60 * 1000)) ||
        (selectedFilters.date.includes('last7days') &&
          new Date(item.date) >= new Date(new Date() - 7 * 24 * 60 * 60 * 1000)) ||
        (selectedFilters.date.includes('thismonth') &&
          new Date(item.date).getMonth() === new Date().getMonth()) ||
        (selectedFilters.date.includes('last2months') &&
          new Date(item.date) >= new Date(new Date() - 2 * 30 * 24 * 60 * 60 * 1000)) ||
        (selectedFilters.date.includes('last3months') &&
          new Date(item.date) >= new Date(new Date() - 3 * 30 * 24 * 60 * 60 * 1000));

      const isInFollowupRange =
        selectedFilters.followup.length === 0 ||
        (selectedFilters.followup.includes('next7days') &&
          new Date(item.followupDate) <= new Date(new Date() + 7 * 24 * 60 * 60 * 1000)) ||
        (selectedFilters.followup.includes('last7days') &&
          new Date(item.followupDate) >= new Date(new Date() - 7 * 24 * 60 * 60 * 1000)) ||
        (selectedFilters.followup.includes('1month') &&
          new Date(item.followupDate) >= new Date(new Date() - 30 * 24 * 60 * 60 * 1000)) ||
        (selectedFilters.followup.includes('2months') &&
          new Date(item.followupDate) >= new Date(new Date() - 2 * 30 * 24 * 60 * 60 * 1000));

      const isCategoryMatch =
        selectedFilters.category.length === 0 || selectedFilters.category.includes(item.category);
      const isStatusMatch = selectedFilters.status.length === 0 || selectedFilters.status.includes(item.finalStatus);

      return isInDateRange && isInFollowupRange && isCategoryMatch && isStatusMatch;
    });

    setTableData(filteredData);
  };

  useEffect(() => {
    fetchData();
    const authenticatedUser = JSON.parse(localStorage.getItem('authenticatedUser'));
    const usernameFromEmail = authenticatedUser.name;
    setUser(usernameFromEmail);
  }, []);

  useEffect(() => {
    localStorage.setItem('filters', JSON.stringify(selectedFilters));
  }, [selectedFilters]);

  const leadsWithTodayFollowUp = tableData.filter((item) => {
    const today = new Date().toISOString().split('T')[0];
    const pastDates = originalTableData.filter((lead) => lead.followupDate < today);

    return item.followupDate === today || pastDates.some((lead) => lead.id === item.id);
  });

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'leads', selectedItemId));
      setTableData(tableData.filter((item) => item.id !== selectedItemId));
      setSelectedItemId(null);
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  function formatDate(dateString) {
    const options = { day: 'numeric', month: 'short', year: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  }

  const openDeleteModal = (itemId) => {
    setSelectedItemId(itemId);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedItemId(null);
    setShowDeleteModal(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('authenticatedUser');
    window.location.href = '/login';
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Positive':
        return 'bg-green-500 text-white';
      case 'Pending':
        return 'bg-yellow-500 text-white';
      case 'Low':
        return 'bg-blue-500 text-white';
      case 'Hold':
        return 'bg-gray-500 text-white';
      case 'Negative':
        return 'bg-red-500 text-white';
      case 'Joined':
        return 'bg-purple-500 text-white';
      default:
        return 'bg-gray-300 text-white';
    }
  }

  return (
    <div className='bg-slate-50  h-screen'>
      <div className='flex justify-between items-center md:mx-8 mb-4'>
        {/* <p className='text-lg '> <span className='font-semibold'>Hi,</span> {user}</p> */}
        <img src={logo} className='w-80' />
        <div className="grid grid-cols-4 gap-2">
          {Object.keys(statusCounts).map((status) => (
            <div key={status} className="bg-pink-400 p-2 rounded text-white">
              <div className="text-sm font-semibold">{status}</div>
              <div className="text-xl font-semibold">{statusCounts[status]}</div>
            </div>
          ))}

        </div>
        <div className='flex items-center w-2/5 justify-end gap-4'>
          <div className='flex items-center w-1/2 justify-end'>
            {leadsWithTodayFollowUp.length > 0 ? (
              <div className="bg-purple-200 p-2 m-4 rounded w-auto custom-scrollbar" style={{ maxHeight: '100px', overflowY: 'auto' }}>
                <p className="text-lg font-semibold text-blue-900">Todays Follow-ups</p>
                <ul>
                  {leadsWithTodayFollowUp.map((lead) => (
                    <li key={lead.id} className='text-sm text-blue-900 cursor-pointer' onClick={() => handleRedirect(lead.id)}>

                      {`${lead.candidateName}  ( ${lead.mobileNumber} )`}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="bg-red-200 p-2 m-4 rounded w-1/2" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <p className="text-lg font-semibold text-red-600">No follow-ups today</p>
              </div>
            )}
          </div>

        </div>
      </div>

      <div className="px-8 flex items-center flex-col justify-start">
        <div className="flex items-center justify-between py-3 w-full">
          <div className='flex items-center'>
           
            <DropdownFilter
              options={["last3days", "last7days", "thismonth", "last2months", "last3months"]}
              label="Date"
              selected={selectedFilters.date}
              onSelect={(value) => setSelectedFilters((prevFilters) => ({ ...prevFilters, date: value }))}
            />
            <DropdownFilter
              options={["next7days", "last7days", "1month", "2months"]}
              label="Follow-up"
              selected={selectedFilters.followup}
              onSelect={(value) => setSelectedFilters((prevFilters) => ({ ...prevFilters, followup: value }))}
            />
            <DropdownFilter
              options={filterOptions.category}
              label="Category"
              selected={selectedFilters.category}
              onSelect={(value) => setSelectedFilters((prevFilters) => ({ ...prevFilters, category: value }))}
            />
            <DropdownFilter
              options={filterOptions.status}
              label="Status"
              selected={selectedFilters.status}
              onSelect={(value) => setSelectedFilters((prevFilters) => ({ ...prevFilters, status: value }))}
            />
            <div className='flex space-x-2'>
              <button aria-label='apply' className="bg-black text-white px-4 py-1 rounded" onClick={handleApplyFilter}>
                Apply
              </button>
              <a href="/joined"><button aria-label='apply' className="bg-green-500 text-white px-4 py-1 rounded" >
                <i className="bi pr-2 bi-bucket"></i>Joined
              </button></a>
              <a href="/negative"><button aria-label='apply' className="bg-red-500 text-white px-4 py-1 rounded" >
                <i className="bi pr-2 bi-bucket"></i>Negative
              </button></a>
            </div>
          </div>
          <div className='flex space-x-2'>
            <a href="/add" ><button className="bg-black rounded px-4 py-1 text-white font-semibold hover:bg-slate-700"><i className="bi bi-person-plus"></i></button> </a>
            <button className="bg-black text-white px-4 py-1 rounded hover:bg-slate-700" onClick={handleDownloadExcel}>
              <i className="bi bi-chevron-bar-down"></i>
            </button>
            <button
              className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600 mr-4"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        </div>
        <div className="table-container custom-scrollbar" style={{ maxHeight: '500px', maxWidth: '1600px', overflowY: 'auto', overflowX: 'auto' }}>
          <table className="border-collapse table-auto border"  >
            <thead className="sticky top-0 bg-gray-200">
              <tr className="bg-slate-200">
                <th className="py-0 text-sm">Date</th>
                <th className="py-0 text-sm">Name</th>
                <th className="py-0 text-sm">Number</th>
                <th className="py-0 text-sm">City</th>
                <th className="py-0 text-sm">Degree</th>
                <th className="py-0 text-sm">Category</th>
                <th className="py-0 text-sm">Type</th>
                <th className="py-0 text-sm">Comments</th>
                <th className="py-0 text-sm">Follow-up On</th>
                <th className="py-0 text-sm">Status</th>
                <th className="py-0 text-sm">Referred By</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item) => (
                <tr key={item.id} className="border-b">
                  <td className="py-0.5 text-sm px-1 border" style={{ minWidth: '80px' }}>{formatDate(item.date)}</td>
                  <td className="py-0.5 text-sm px-1 border">{item.candidateName}</td>
                  <td className="py-0.5 text-sm px-1 border">{item.mobileNumber}</td>
                  <td className="py-0.5 text-sm px-1 border">{item.city}</td>
                  <td className="py-0.5 text-sm px-1 border">{item.degree}</td>
                  <td className="py-0.5 text-sm px-1 border">{item.category}</td>
                  <td className="py-0.5 text-sm px-1 border">{item.type}</td>
                  <td className="py-0.5 text-sm px-1 border">
                    {item.comments.length > 0 ? item.comments[item.comments.length - 1].comment : ''}
                  </td>
                  <td className="py-0.5 text-sm px-1 border" style={{ minWidth: '80px' }}>{formatDate(item.followupDate)}</td>
                  <td className="py-0.5 text-sm px-1 border"><span className={`badge rounded px-1 py-0.5 ${getStatusColor(item.finalStatus)}`}>
                    {item.finalStatus}
                  </span></td>
                  <td className="py-0.5 text-sm px-1 border">{item.referredBy}</td>
                  <td className="py-0.5 text-sm px-1 ">
                    <button className="bg-purple-400 rounded px-2 py-1 text-white" onClick={() => handleRedirect(item.id)}>
                      View
                    </button>
                  </td>
                  <td className="py-0.5 text-sm px-1 ">
                    <button className="bg-red-400 rounded px-2 py-1 text-white" onClick={() => openDeleteModal(item.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {showDeleteModal && (
            <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex justify-center items-center">
              <div className="bg-white p-8 rounded">
                <p className="text-lg font-semibold mb-4">Are you sure you want to delete this item?</p>
                <div className="flex justify-end">
                  <button className="bg-red-400 px-4 py-2 text-white rounded mr-2" onClick={handleDelete}>Delete</button>
                  <button className="bg-gray-300 px-4 py-2 text-gray-800 rounded" onClick={closeDeleteModal}>Cancel</button>
                </div>
              </div>
            </div>
          )}
        </div>

      </div>
    </div>
  )
}

const DropdownFilter = ({ options, label, selected, onSelect }) => {
  const handleChange = (selectedOptions) => {
    onSelect(selectedOptions.map((option) => option.value));
  };

  const formattedOptions = options.map((option) => ({ value: option, label: option }));

  return (
    <div className="relative">
      <div className="flex flex-col">
        <Select className="px-2"
          isMulti
          placeholder={label}
          value={formattedOptions.filter((option) => selected.includes(option.value))}
          onChange={handleChange}
          options={formattedOptions}
        />
      </div>
    </div>
  );
};
export default Home