import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, query, getDocs } from 'firebase/firestore';
import { db } from '../Firebase';
import StatusDropdown from '../components/StatusDropdown';
import { useNavigate } from 'react-router-dom';

const EditLead = () => {
  const { leadId } = useParams();
  const [leadData, setLeadData] = useState(null);
  const [editedLeadData, setEditedLeadData] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [followUpDate, setFollowUpDate] = useState(null); // State for follow-up date
  const [admins, setAdmins] = useState([]); // State to store the list of admins
  const [selectedAdmin, setSelectedAdmin] = useState(''); // State to store the selected admin
  const [isEditMode, setIsEditMode] = useState(false); // State to track edit mode

  const navigate = useNavigate();
  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because months are zero-indexed
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure two digits for minutes
    const seconds = date.getSeconds().toString().padStart(2, '0'); // Ensure two digits for seconds
    const time = `${hours}:${minutes}:${seconds}`;
    return `${day}/${month}/${year}, ${time}`;
  };

  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const leadDoc = await getDoc(doc(db, 'leads', leadId));
        if (leadDoc.exists()) {
          const data = { id: leadDoc.id, ...leadDoc.data() };
          setLeadData(data);
          setEditedLeadData({ ...data });
          // Initialize follow-up date with leadData
          setFollowUpDate(new Date(data.followupDate));
        } else {
          console.log("Lead not found");
        }
      } catch (error) {
        console.error('Error fetching lead data:', error);
      }
    };

    const fetchAdmins = async () => {
      try {
        const adminsCollection = collection(db, 'admin');
        const adminsSnapshot = await getDocs(adminsCollection);
        const adminsData = adminsSnapshot.docs.map(doc => doc.data().name);
        setAdmins(adminsData);
      } catch (error) {
        console.error('Error fetching admins:', error);
      }
    };

    fetchLeadData();
    fetchAdmins();
  }, [leadId]);

  const handleAddComment = async () => {
    if (!newComment.trim()) {
      return;
    }

    const updatedComments = [...editedLeadData.comments, { comment: newComment, dateTime: new Date().toISOString() }];
    setNewComment('');

    try {
      await updateDoc(doc(db, 'leads', leadId), { comments: updatedComments });
      setEditedLeadData(prevData => ({ ...prevData, comments: updatedComments }));
      setLeadData(prevData => ({ ...prevData, comments: updatedComments }));
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleStatusUpdate = async (newStatus) => {
    try {
      await updateDoc(doc(db, 'leads', leadId), { finalStatus: newStatus });
      setEditedLeadData(prevData => ({ ...prevData, finalStatus: newStatus }));
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleAdminChange = async (selectedAdmin) => {
    try {
      await updateDoc(doc(db, 'leads', leadId), { admin: selectedAdmin });
      setEditedLeadData(prevData => ({ ...prevData, admin: selectedAdmin }));
    } catch (error) {
      console.error('Error updating admin:', error);
    }
  };

  const today = new Date();

  if (!leadData) {
    return <div>Loading...</div>;
  }

  const handleEditMode = () => {
    setIsEditMode(true);
  };

  const handleSaveChanges = async () => {
    try {
      // Update lead data in Firestore
      await updateDoc(doc(db, 'leads', leadId), editedLeadData);
      setLeadData(editedLeadData); 
      setIsEditMode(false);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const categoryToTypes = {
    Testing: ['Java Selenium', 'Python Selenium'],
    Cloud: ['AWS Devops', 'Azure Devops'],
    Development: ['Java Fullstack', 'Python Fullstack', 'Dot Net Fullstack', 'Fullstack', 'Front-End', 'Back-End', 'JS Family', 'MERN Stack'],
    'Oracle SQL': ['PLSQL Development', 'Production Support'],
    'UIUX Design': ['UIUX only', 'UIUX+Development'],
    'Data Analytics/Data Science': ['DA Stack', 'DataScience', 'PowerBI', 'Tableau', 'Python with ML', 'AI'],
    'Fresher Placement': ['Mention Technology name'],
    'Experienced Placement': ['Mention Technology name'],
    'Documents/Test only': ['Work Experience Document', 'Certification test', 'Client Round', 'Online Test', 'Single Interview'],
    Others: [''] // Editable box (Left in blank)
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <div className='mb-2 flex items-center justify-between'>
        <div>
          <div className='flex items-center'>
            <h1 className="text-2xl mr-4 font-bold">{leadData.candidateName}</h1>
            {leadData && <StatusDropdown
              currentStatus={leadData.finalStatus}
              handleStatusUpdate={handleStatusUpdate}
            />}
          </div>
          <span className='text-lg font-normal'>{leadData.mobileNumber}</span>
        </div>
        <p className='font-semibold'> <i class="bi bi-calendar2-event"></i> {leadData.date}</p>
      </div>

      <div className="grid grid-cols-2 gap-8">
        <div>
          <div className="mb-4">
            <p className="font-semibold inline-block w-24">Admin</p>
            {isEditMode ? (
              <select
                value={editedLeadData.admin}
                onChange={(e) => setEditedLeadData({ ...editedLeadData, admin: e.target.value })}
                className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
              >
                {admins.map((admin, index) => (
                  <option key={index} value={admin}>{admin}</option>
                ))}
              </select>
            ) : (
              <p className="inline-block px-4 py-2">{leadData.admin}</p>
            )}
          </div>
          {/* Other fields */}
          <div className="mb-4">
            <p className="font-semibold inline-block w-24">Branch</p>
            <p className="inline-block">{isEditMode ? (
              <input
                type="text"
                value={editedLeadData.branch}
                onChange={(e) => setEditedLeadData({ ...editedLeadData, branch: e.target.value })}
                className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
              />
            ) : (
              <p className="inline-block px-4 py-2">{leadData.branch}</p>
              
            )}</p>
          </div>
          {/* Follow-up Date */}
          <div className="mb-4">
            <p className="font-semibold inline-block w-24">Follow-up Date</p>
            {isEditMode ? (
              <input
                type="date"
                value={followUpDate ? followUpDate.toISOString().split('T')[0] : ''}
                onChange={(e) => {
                  const newDate = new Date(e.target.value);
                  setFollowUpDate(newDate);
                  const updatedDate = newDate ? newDate.toISOString().substr(0, 10) : null;
                  setEditedLeadData({ ...editedLeadData, followupDate: updatedDate });
                }}
                min={today.toISOString().split('T')[0]}
                className="inline-block px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
              />
            ) : (
              <p className="inline-block px-4 py-2">{leadData.followupDate}</p>
            )}
          </div>
          {/* Referred By */}
          <div className="mb-4">
            <p className="font-semibold inline-block w-24">Referred By</p>
            <p className="inline-block">{isEditMode ? (
              <input
                type="text"
                value={editedLeadData.referredBy}
                onChange={(e) => setEditedLeadData({ ...editedLeadData, referredBy: e.target.value })}
                className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
              />
            ) : (
              <p className="inline-block px-4 py-2">{leadData.referredBy}</p>
            )}</p>
          </div>
          {/* Degree */}
          <div className="mb-4">
            <p className="font-semibold inline-block w-24">Degree</p>
            <p className="inline-block">{isEditMode ? (
              <input
                type="text"
                value={editedLeadData.degree}
                onChange={(e) => setEditedLeadData({ ...editedLeadData, degree: e.target.value })}
                className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
              />
            ) : (
              <p className="inline-block px-4 py-2">{leadData.degree}</p>
            )}</p>
          </div>
          {/* Category */}
          <div className="mb-4">
            <p className="font-semibold inline-block w-24">Category</p>
            <p className="inline-block">{isEditMode ? (
              <select
              required
              id="category"
              name="category"
              value={editedLeadData.category}
              onChange={(e) => setEditedLeadData({ ...editedLeadData, category: e.target.value })}
              className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
            >
              <option value="Testing">Testing</option>
              <option value="Cloud">Cloud</option>
              <option value="Development">Development</option>
              <option value="Oracle SQL">Oracle SQL</option>
              <option value="UIUX Design">UIUX Design</option>
              <option value="Data Analytics/Data Science">Data Analytics/Data Science</option>
              <option value="Fresher Placement">Fresher Placement</option>
              <option value="Experienced Placement">Experienced Placement</option>
              <option value="Documents/Test only">Documents/Test only</option>
              <option value="Others">Others</option>
            </select>
            ) : (
              <p className="inline-block px-4 py-2">{leadData.category}</p>

            )}</p>
          </div>
          {/* Type */}
          <div className="mb-4">
            <p className="font-semibold inline-block w-24">Type</p>
            <p className="inline-block">
  {isEditMode ? (
    editedLeadData.category === 'Others' ? (
      <input
        type="text"
        id="type"
        name="type"
        value={editedLeadData.type}
        onChange={(e) => setEditedLeadData({ ...editedLeadData, type: e.target.value })}
        placeholder="Enter custom type"
        className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
        />
    ) : (
      <>
        {editedLeadData.category === 'Fresher Placement' || editedLeadData.category === 'Experienced Placement' ? (
          <input
            type="text"
            id="type"
            name="type"
            value={editedLeadData.type}
            onChange={(e) => setEditedLeadData({ ...editedLeadData, type: e.target.value })}
            placeholder="Mention Technology name"
            className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
            />
        ) : (
          <select
            id="type"
            name="type"
            value={editedLeadData.type}
            onChange={(e) => setEditedLeadData({ ...editedLeadData, type: e.target.value })}
            className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
            >
            <option value="">Select Type</option>
            {categoryToTypes[editedLeadData.category] && categoryToTypes[editedLeadData.category].map((type, index) => (
              <option key={index} value={type}>{type}</option>
            ))}
          </select>
        )}
      </>
    )
  ) : (
    <p className="inline-block px-4 py-2">{leadData.type}</p>
  )}
</p>

          </div>
          {/* City */}
          <div>
            <p className="font-semibold inline-block w-24">City</p>
            <p className="inline-block">{isEditMode ? (
              <input
                type="text"
                value={editedLeadData.city}
                onChange={(e) => setEditedLeadData({ ...editedLeadData, city: e.target.value })}
                className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
              />
            ) : (
              <p className="inline-block px-4 py-2">{leadData.city}</p>

            )}</p>
          </div>
          <div className='pt-4'>
            {isEditMode ? (
              <div>
                <button
                  onClick={handleSaveChanges}
                  className="bg-green-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                  Save Changes
                </button>
                <button
                  onClick={() => setIsEditMode(false)}
                  className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-600 focus:outline-none focus:ring focus:ring-gray-200"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className='flex space-x-2 items-center'>
              <button
                onClick={handleEditMode}
                className="bg-yellow-400 text-white px-4 py-2 rounded-md hover:bg-yellow-300 focus:outline-none  "
              >
                Edit
              </button>
            <button
              onClick={() => navigate('/')}
              className="bg-slate-900  text-white px-4 py-2 rounded-md hover:bg-slate-600 focus:outline-none focus:ring focus:ring-slate-200"
            >
              Back
            </button>
            </div>
            )}
          </div>
        </div>
        <div>
          {/* Comments */}
          <div className="mb-4 h-64 overflow-y-auto custom-scrollbar">
            <p className="font-semibold inline-block w-24">Comments</p>
            <ul className="mt-2">
              {leadData.comments
                .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                .map((comment, index) => (
                  <li key={index} className="text-sm text-gray-600 border border-gray-300 rounded-md p-3 mb-2 mx-1 relative">
                    <span className="absolute bottom-0 right-0 text-xs mx-4 text-gray-600">
                      {formatDate(comment.dateTime)}
                    </span>
                    <p>{comment.comment}</p>
                  </li>
                ))
              }
            </ul>
          </div>
          {/* Add Comment */}
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Add a new comment"
              className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-slate-200 flex-grow"
              required
            />
            <button
              onClick={handleAddComment}
              className="bg-slate-900 text-white px-4 py-2 rounded-md hover:bg-slate-600 focus:outline-none focus:ring focus:ring-slate-200"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLead;
